import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import Footer from '../components/Footer';
import Nav from '../components/Nav';

const Layout = ({ children }) => {
  return <ThemeProvider
    breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
    minBreakpoint="xs"
  >
    <Nav />
    <main>
      {children}
    </main>
    <Footer />
  </ThemeProvider>;
};

export default Layout;
