import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

import styles from './styles.module.scss';

const LoadingContainer = () => {
  return <Container className={styles.loading}>
    <Row className={styles.container}>
      <Col className="pt-5 pb-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    </Row>
  </Container>;
}

export default LoadingContainer;
