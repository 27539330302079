import React, { useState } from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const menu = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Winners",
      url: "/winners",
    },
    {
      name: "How it works",
      url: "/how-it-works",
    },
  ];

  return <nav>
    <div className={styles.nav}>
      <div className={styles.nav_container}>
        <div className="d-flex justify-content-between">
          <div className={styles.nav_logo}>
            <Link to="/">
              <img src="/images/twogether-logo.svg" alt="Twogether" />
            </Link>
          </div>
          <div>
            <div
              className={styles.nav_toggle + " d-md-none"}
              onClick={() => toggleOpen()}
            >
              <div
                className={
                  styles.nav_toggle_line + " " + (isOpen ? styles.active : "")
                }
              ></div>
              <div
                className={
                  styles.nav_toggle_line + " " + (isOpen ? styles.active : "")
                }
              ></div>
              <div
                className={
                  styles.nav_toggle_line + " " + (isOpen ? styles.active : "")
                }
              ></div>
            </div>
            <div
              className={
                styles.nav_links +
                " align-items-center " +
                (isOpen ? styles.active : "")
              }
            >
              {menu.map((page, idx) => (
                <div
                  key={idx}
                  className={styles.nav_link}
                  onClick={() => setIsOpen(false)}
                >
                  <Link to={page.url}>{page.name}</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>;
};

export default Nav;
