import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppProvider } from './contexts/App';
import Loading from './pages/Loading';
import Layout from './pages/Layout';
import ScrollToTop from './ScrollToTop';

const Home = lazy(() => import('./pages/Home'));
const Winners = lazy(() => import('./pages/Winners'));
const HowItWorks = lazy(() => import('./pages/HowItWorks'));

function App() {
  return <>
    <AppProvider>
      <React.Suspense fallback={<Loading />}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/winners"
                element={<Winners />}
              />
              <Route
                path="/how-it-works"
                element={<HowItWorks />}
              />
              <Route path="*" element={<Home />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </React.Suspense>
    </AppProvider>
  </>;
}

export default App;
