import React from 'react';
import LoadingContainer from '../../components/LoadingContainer';

import styles from './styles.module.scss';

const Loading = () => {
  return <>
    <section className={styles.container}>
      <div className={styles.loading}>
        <LoadingContainer />
      </div>
    </section>
  </>;
}

export default Loading;
