import React, { createContext, useState } from 'react';
import Cache from '../system/Cache';
import StateNormalizer from '../system/StateNormalizer';
import AppClass from '../class/App.class';
import AppConfig from '../config/AppConfig';

export const AppContext = createContext({
  state: AppClass,
  dispatch: () => null,
});

export const AppProvider = ({ children }) => {
  const cache = new Cache();
  const stateNormalizer = new StateNormalizer();

  const [appState, setAppState] = useState(() => {
    const localAppState = stateNormalizer.normalize(
      new AppClass(),
      cache.get('app') ?? {}
    );

    if (null === localAppState.account.token) {
      window.location.replace(AppConfig.apiHost);
    }

    return stateNormalizer.normalize(new AppClass(), localAppState);
  });

  const dispatchApp = (app) => {
    cache.set('app', app);
    setAppState(stateNormalizer.normalize(new AppClass(), app));
  };

  return (
    <AppContext.Provider value={[appState, dispatchApp]}>
      {children}
    </AppContext.Provider>
  );
};
