class App {
  account = {
    token: null,
    refreshToken: null,
    user: {
      id: null,
      name: '',
      email: '',
      image: '',
      departments: [],
    },
  };
  heroPeriod = null;
  staffMembers = [];
  departments = [];
  lastApiCheck = null;

  setAccountToken = (token) => {
    this.account.token = token;

    return this;
  };

  setAccountRefreshToken = (refresh_token) => {
    this.account.refreshToken = refresh_token;

    return this;
  };
}

export default App;
