import React from 'react';
import AppConfig from '../../config/AppConfig';

import styles from './styles.module.scss';

const Footer = () => {
  return <footer>
    <div className={styles.footer}>
      <div className={styles.footer_container}>
        <div>
          <a href={AppConfig.apiHost + '/admin'}>Admin</a>
        </div>
        <p>
          &copy; {(new Date()).getFullYear()} Twogether Creative Limited
        </p>
      </div>
    </div>
  </footer>;
}

export default Footer;
