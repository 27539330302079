class StateNormalizer {
  normalize(object, data) {
    const keys = Object.keys(data);

    for (const key in keys) {
      if (object.hasOwnProperty(keys[key])) {
        object[keys[key]] = data[keys[key]];
      }
    }

    return object;
  }
};

export default StateNormalizer;
